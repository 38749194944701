<template>
  <TransitionRoot as="template" :show="is_opened">
    <Dialog as="div" class="relative z-10" @close="open(false)">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        />
      </TransitionChild>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div
          class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6"
            >
              <div class="mt-2">
                <p class="text-sm text-gray-500">
                  Forgot your password? We'll send you a link to reset it.
                </p>
                <b class="text-sm">
                  Please note that the password reset link is only valid for 5
                  minutes.
                </b>
              </div>
              <form @submit.prevent="sendLink">
                <div class="mt-2">
                  <input
                    id="email"
                    v-model="email"
                    name="email"
                    type="email"
                    required
                    autocomplete="off"
                    placeholder="Email address"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
                <div
                  class="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3 flex"
                >
                  <button
                    v-if="!is_loading"
                    class="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                  >
                    Send
                  </button>
                  <TheLoader v-else class="inline-flex sm:col-start-3" />
                  <button
                    ref="cancelButtonRef"
                    type="button"
                    class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                    @click="open(false)"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { useToast } from "vue-toastification";
import TheLoader from "~/components/TheLoader.vue";

const props = defineProps({
  open: {
    type: Function,
    required: true,
  },
  is_opened: {
    type: Boolean,
    required: true,
  },
});

const supabase = useSupabaseClient();

const email = ref("");
const is_loading = ref(false);

const sendLink = async () => {
  is_loading.value = true;
  const { error } = await supabase.auth.resetPasswordForEmail(email.value, {
    redirectTo: `${window.location.href}/reset-password`,
  });
  if (error) {
    useToast().error(`${error}. Try again later`);
    is_loading.value = false;
    return;
  }
  is_loading.value = false;
  props.open(false);
  useToast().info("Please check your mail");
  email.value = "";
};
</script>
